import React from 'react';
import { Oval } from 'react-loader-spinner';

import { Hand, Circle, Preloader } from './Splash.styles';

const Splash = () => (
  <Preloader id="clgo-preloader">
    <Oval
      visible
      height="48"
      width="48"
      ariaLabel="blocks-loading"
      wrapperClass="blocks-wrapper"
      color="#473592"
      secondaryColor="#E2E8F0"
      strokeWidth={4}
      strokeWidthSecondary={4}
    />
  </Preloader>
);

export default Splash;
