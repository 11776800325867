import { hasLabEcom } from '../../labs/hasLabEcom';
import { getLocalStorage } from '../../utils';
import { LOCAL_TAGS_KEY } from '../../Constants';

const decodeQueryTags = (tags) => decodeURIComponent(tags).split(',');
const getLowerCaseTags = (tags) => tags.map((tag) => tag.toLowerCase());

const filterByTags = (rows, tags) => {
  if (hasLabEcom() && tags !== '') {
    const decodedTags = decodeQueryTags(tags);
    const localTags = getLocalStorage(LOCAL_TAGS_KEY);

    if (!localTags) {
      return rows;
    }

    try {
      const localTagsList = Object.keys(localTags).map((key) => localTags[key]) || [];
      const conversatiosWithSelectedTags = localTagsList
        .filter((tag) => decodedTags.every((t) => tag.conversationTags && getLowerCaseTags(tag.conversationTags)
          .includes(t.toLowerCase())));
      const conversationIds = conversatiosWithSelectedTags.map((filtered) => filtered.conversationId);
      return rows.filter((row) => conversationIds.includes(row.id));
    } catch (error) {
      console.error('Incompatible tags version', error);
      localStorage.removeItem(LOCAL_TAGS_KEY);
    }
  }
  return rows;
};

export default filterByTags;
