export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_APP_SECTION = 'SET_APP_SECTION';
export const SET_APP_SLIDE = 'SET_APP_SLIDE';
export const OPEN_SLIDE = 'OPEN_SLIDE';
export const CLOSE_SLIDE = 'CLOSE_SLIDE';
export const SET_ALL_USERS = 'SET_ALL_USERS';

// Litten slide constants
export const SET_LITTEN_SLIDE = 'SET_LITTEN_SLIDE';
export const OPEN_LITTEN_SLIDE = 'OPEN_LITTEN_SLIDE';
export const CLOSE_LITTEN_SLIDE = 'CLOSE_LITTEN_SLIDE';

// actions releated to chats available or taken
export const SET_CHAT_INFO = 'SET_CHATS_LIST';
export const REMOVE_CHAT = 'REMOVE_CHAT';
export const ADD_CHAT = 'ADD_CHAT';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const UPDATE_ONE_CHAT = 'UPDATE_ONE_CHAT';

// releated to websites
export const SET_WEBSITES = 'SET_WEBSITES';

// releated to history
export const SET_HISTORY = 'SET_HISTORY';
export const SET_LANG = 'SET_LANG';

export const visitorContextTypes = {
  ADD_CONTEXT_MESSAGE: 'ADD_CONTEXT_MESSAGE',
  SET_SELECTED_CONVERSATION: 'SET_SELECTED_CONVERSATION',
  SET_CONTEXT_INFO: 'SET_CONTEXT_INFO',
  SET_TOOLTIP: 'SET_TOOLTIP',
};
