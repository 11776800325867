import React from 'react';

import { ChatBodyPanel } from '../../../GlobalStyles/Layouts';
import Tooltip from '../../Tooltip/Tooltip';
import capitalize from '../../../utils/capitalize';

import {
  Block,
  Icon,
  Link,
  Spanpadding,
  Subtitle,
} from './ContactDetails.styles';

const ContactDetailsPanel = ({ data }) => {
  const hasProtocol = (url = '') => (['http://', 'https://'].some((protocol) => (url.startsWith(protocol))));
  const formatSiteString = (site) => {
    const isUrl = (siteValue = '') => (
      ['http://', 'https://', 'www.', '.com'].some((urlSegment) => (siteValue.includes(urlSegment)))
    );

    return isUrl(site) ? site : capitalize(site);
  };

  const AdditionalInfoRender = ({ data }) => data.info.filter((info) => !!info.value).map((inf) => (
    <Spanpadding className="detail-site" key={inf.title + inf.value}>
      {!!inf.icon && <Icon className={inf.icon} />}
      {!!inf.title && <strong>{`${inf.title}:`}</strong>}
      {
        inf.link
          ? (
            <Tooltip
              direction="top"
              content={hasProtocol(inf.link) ? inf.link : `http://${inf.link}`}
              tooltipWidth="auto"
              alignmentX="-50%"
            >
              <Link>
                <a
                  href={hasProtocol(inf.link) ? inf.link : `http://${inf.link}`}
                  target="_blank"
                  className="Link-panel"
                  rel="noopener noreferrer"
                >
                  {formatSiteString(inf.value)}
                </a>
              </Link>
            </Tooltip>
          )
          : formatSiteString(inf.value)
      }
    </Spanpadding>
  ));

  return (
    <ChatBodyPanel>
      {
        data.map((e) => (
          <Block key={e.title}>
            <Subtitle>{e.title}</Subtitle>
            <AdditionalInfoRender data={e} />
          </Block>
        ))
      }
    </ChatBodyPanel>
  );
};

export default ContactDetailsPanel;
