import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { closeSlide, saveSlide } from '../../Store/actions/slide';
import { channelIcons, statusClient } from '../../utils/states';
import { useGoToUrl } from '../../customHooks/goToRoute';
import { translate } from '../../services/translate';
import capitalize from '../../utils/capitalize';
import { FORMATS_DATE_TIME } from '../../Constants';
import { Chats, Props, StateAllChats } from '../../Interfaces/AllChats';
import { ContextTagContainer } from '../../Components/VisitorContext/Components/ContextTagContainer/ContextTagContainer.component';

import AllChatsComponent from './allChats.component';
import { BtnShowChat, LeadChannel, LeadStatus } from './allChats.style';

const useQuery = () => new URLSearchParams(useLocation().search);
const maxLength = (str: string, n: number) => {
  if (str) {
    return str.length <= n ? str : `${str.substr(0, 16)}...`;
  }
  return '';
};

const AllChats = ({ loading, socket, ...rowProps }: Props) => {
  const slidePanel = useSelector((state: StateAllChats) => state.slidePanel);
  const chats = useSelector((state: StateAllChats) => state.chatInfo);
  const websites = useSelector((state: StateAllChats) => state.websites);
  const users = useSelector((state: StateAllChats) => state.users);
  const lang = useSelector((state: StateAllChats) => state.getLanguage);
  const { account } = useSelector((state: StateAllChats) => state.auth);
  const dispatch = useDispatch();
  const hasScriptInstalled = websites.hasAnyScriptInstalled;
  const goTo = useGoToUrl();
  const { tab } = useParams<{
    tab: 'available' | 'mine' | 'removed' | 'taken' | 'all';
  }>();
  const queryChatId = useQuery().get('chatId'); // por si trae querys
  const queryShow = useQuery().get('show'); // para ver si abre detalles o no
  const history = useHistory();
  /** validando para que no explote al introducir algo como /live/adsdfs */
  const isValidTab = ['all', 'taken', 'available'].includes(tab);
  const [currentTab, setCurrentTab] = useState<string>('available');
  if (tab && !isValidTab) goTo(`/live/visitors/${currentTab}`);

  const sitesOptions: {
    label: string;
    value: string;
  }[] = websites.sites.map((webs) => ({
    label: webs?.title,
    value: webs?.id,
  }));
  sitesOptions.unshift({
    label: translate('visitors_section.all_sites'),
    value: 'all',
  });

  moment.defineLocale(lang, {
    relativeTime: {
      s: `< 1 ${translate('minute')}`,
      ss: `< 1 ${translate('minute')}`,
    },
  });

  const localizeFormatter = (lang: 'en' | 'es' | 'pt') => (time: string) => {
    const formatString = FORMATS_DATE_TIME[lang];
    return formatString ? moment(time).format(formatString) : null;
  };

  const getTime = useCallback(
    (chatTime: string) => {
      const last24Hrs = moment(chatTime).subtract(24, 'hours').format();
      const lastConvTime = moment(chatTime).format();
      if (lastConvTime >= last24Hrs) return moment(chatTime).fromNow();
      const formatter = localizeFormatter(lang);
      return formatter(chatTime);
    },
    [lang],
  );

  const getTableData = useCallback(
    (chats: Chats[]) => chats.map((e: Chats) => {
      const userIdSearch = e?.participants?.find((e) => e.type === 'user');
      const userId = userIdSearch ? userIdSearch.id : '';
      const user = users.find((u: { id: string }) => u.id === userId);
      const visitorName = e.visitorName && e.visitorName !== 'Cliente'
        ? capitalize(e.visitorName)
        : translate('client');

      return {
        id: e.id,
        webId: e.websiteId,
        table: [
          {
            key: 'name',
            label: (
              <span style={{ textTransform: 'capitalize' }}>
                {visitorName}
              </span>
            ),
            labelMobile: <span>{maxLength(e.id, 16)}</span>,
            value: visitorName,
          },
          {
            key: 'lastContact',
            label: <span>{getTime(e.lastMessageDate)}</span>,
            value: e.lastMessageDate,
          },
          {
            key: 'status',
            label: (
              <LeadStatus statusColor={statusClient[e.clientStatus]?.color}>
                <i className="icon-circle" />
                {` ${translate(statusClient[e.clientStatus]?.key)}`}
              </LeadStatus>
            ),
            value: e.clientStatus,
          },
          {
            key: 'channel',
            label: (
              <LeadChannel>
                <i className={`icon-${channelIcons[e.channel]}`} />
                {` ${e.channel?.toLowerCase()}`}
              </LeadChannel>
            ),
            value: e.channel,
          },
          {
            key: 'chattingWith',
            label: <span className="userName">{user ? user.name : '-'}</span>,
            value: user ? user.name : '',
          },
          {
            key: 'buttonAction',
            label: (
              <BtnShowChat>
                {translate('visitors_section.general_table.view_chat')}
              </BtnShowChat>
            ),
            value: 'ver chat',
          },
          {
            key: 'tags',
            label: (
              <ContextTagContainer
                accountId={account.id}
                socket={socket}
                conversationId={e.id}
              />
            ),
            value: '',
          },
        ],
      };
    }),
    [account.id, getTime, socket, users],
  );

  useEffect(() => {
    if (queryChatId && tab) {
      const newChats = tab === 'all' ? [...chats.available, ...chats.taken] : chats[tab];
      if (newChats.length > 0) {
        const index = newChats.findIndex((e: Chats) => e.id === queryChatId);
        if (slidePanel.isFirstLoad) {
          dispatch(
            saveSlide({
              open: true,
              content: {
                key: queryShow ? 'actions' : null,
                selectedIndex: index,
                id: queryChatId,
              },
            }),
          );
        }
      }
    } else if (slidePanel.open) {
      dispatch(closeSlide());
      history.replace({ search: '' });
    }
  }, [chats, chats.available, chats.taken]);

  const OnTabPessCallback = (tabId: string) => {
    if (slidePanel.open) dispatch(closeSlide());
    if (tabId !== 'white') {
      setCurrentTab(tabId);
      history.push(`/live/visitors/${tabId}`);
    }
  };

  return (
    <AllChatsComponent
      isOpenSlide={slidePanel.open}
      getTableData={getTableData}
      sitesOptions={sitesOptions}
      chats={chats}
      goTo={goTo}
      slidePanel={slidePanel}
      hasScriptInstalled={hasScriptInstalled}
      socket={socket}
      loading={loading}
      OnTabPessCallback={OnTabPessCallback}
      preselectedConver={queryChatId}
      preselectedTab={isValidTab ? tab : false}
      websites={websites}
      isLittenChatPreviewOpen={false}
      {...rowProps}
    />
  );
};

export default AllChats;
