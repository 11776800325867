import React from 'react';

import { TitleChatWindow } from '../../../GlobalStyles';
import {
  MainContainerPanel, SubcontainerPanel, ChatHeaderPanel,
} from '../../../GlobalStyles/Layouts';
import { translate } from '../../../services/translate';

import {
  Icon, EmptyContainer, Square,
} from './ContactDetails.styles';
import ContactDetailsPanel from './ContactDetailPanel.component';

const EmptyState = () => {
  const containerWidths = [10, 20, 15, 8, 30, 50, 20, 25, 40, 44, 55];
  return (
    <EmptyContainer>
      { containerWidths.map((w) => <Square width={w} key={Math.random()} />) }
    </EmptyContainer>
  );
};

const ContactDetailsView = ({
  dataToShow,
  closeSlide,
}) => {
  return (
    <MainContainerPanel>
      <SubcontainerPanel>
        <ChatHeaderPanel>
          <TitleChatWindow data-testid="contact-details-title-window">
            {translate('visitors_section.contact_details.contact_details')}
          </TitleChatWindow>
          <Icon className="icon icon-close-panel" onClick={closeSlide} />
        </ChatHeaderPanel>
        {
          dataToShow
            ? <ContactDetailsPanel data={dataToShow} />
            : <EmptyState />
        }
      </SubcontainerPanel>
    </MainContainerPanel>
  );
};

// export default connect(mapStateToProps, { closeSlide })(ContactDetailsView);
export default ContactDetailsView;
