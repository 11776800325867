export default {
  contact: 'Contact',
  unknown: 'Unknown',
  client: 'Client',
  minute: 'minute',
  of: 'of',
  return_crm: 'Return to the platform',
  notifications_menu: 'Notifications',
  visitors_section: {
    visitors: 'Visitors',
    without_operators: 'Without operators',
    chatting: 'Other operators',
    all: 'All',
    all_sites: 'All sites',
    select_a_site: 'Select a site',
    select_a_tag: 'Select a tag',
    tagIsPremium: 'You can filter your conversations starting with the Premium plan.',
    search_chat: 'Search chat',
    online: 'Online',
    offline: 'Offline',
    no_chats_found: "We can't find chats for your search.",
    review_filter: 'Check filter selections or try another search.',
    general_table: {
      name: 'Name',
      channel: 'Channel',
      actions: 'Actions',
      state: 'State',
      last_message: 'Last message',
      assigned_to: 'Assigned to',
      condition: 'State',
      intervened_by: 'Intervened by',
      view_chat: 'View chat',
      two_hr_ago: '2 hours ago',
    },
    contact_details: {
      contact_details: 'Contact details',
      general_info: 'General info',
      browser_information: 'Browser information',
      first_contact: 'First contacto',
      site: 'Site',
      source: 'URL',
      medium: 'Medium',
      device_information: 'Device information',
      source_device: 'Source device',
      ip_address: 'IP address',
      operating_system: 'Operating system',
      browser: 'Browser',
    },
    chat_preview: {
      chat_title: 'Chat',
      chat: 'Chat',
      enter_chat_msg: 'You are about to enter the chat.',
      inactive_robot_msg: 'The robot will be inactive for this client, ask for the missing data.',
      dont_show_again: 'Don\'t show this again.',
      cancel: 'Cancel',
      continue: 'Continue',
      enter_chat: 'Enter chat',
      is_chatting_with_contact: ' is chatting with this contact.',
    },
    intervention_error: {
      chat_already_taken_error: 'Conversation not available',
      chat_already_taken_info: 'This conversation has already been intervened by an operator, please select another.',
    },
    warning_message: {
      chat_intervened: ' This chat is tapped from the',
      chat_disclaimer: ' site, if you enter to chat, no participant will be muted.',
    },
    all_tags: 'All tags',
  },
  my_chats_section: {
    my_chats: 'My chats',
    active_chats: 'Active chats',
    without_operators: 'Without operator',
    end_chat: 'End chat',
    close_chat_question: 'Are you sure you want to close this chat?',
    close_warning: 'If you close this chat without mail or telephone, it will not appear in the clients list.',
    unread_msg: 'Unread messages',
    transfer_chat: 'Transfer Chat',
    transfer_to: 'Transfer to',
    write_reply: 'Write a reply',
    choose_agent: 'Choose an online agent',
    when_transfer: 'When transferring, you will no longer be able to chat with the contact.',
    you_transferred_to: 'You transferred the chat to',
    undo: 'Undo',
    no_operators: 'No online operators',
    to_transfer_you_need: 'To transfer a chat you need another online agent.',
    understood: 'Understood',
    interacctions_with_conversation: {
      you_joined_chat: 'You joined the chat',
      joined_chat: 'joined the chat',
      leave_chat: 'end the chat',
      transferred_to_you: 'transferred the chat to you',
      transferred_from_to: 'transferred the chat to',
      finished_conversation: 'is offline, this conversation can still be viewed from History section.',
      end_chat: 'Chat ended on',
      last_message: 'Last message on',
    },
    chat_multimedia: {
      errors: {
        type_not_allowed: 'File type is not allowed',
        max_file_length: 'Maximum file size exceeded (60MB maximum)',
        general_error: 'There was a problem uploading a file, try again later',
        load_file_error: "We can't load a file",
      },
      type_extensions_title: "What file type can't be sent as an attachment?",
      type_extensions: 'https://help.cliengo.com/hc/en-us/articles/360044633033',
      write_message: 'Write a message',
      file_format_error: "Some files can't be load due to format. You can find more information in our ",
      help_center: 'help center.',
      cancel: 'Cancel',
      add_more: 'Add more',
      remove_file: 'Remove file',
    },
    emoji_section: {
      smileys_people: 'Smileys & People',
      animals_nature: 'Animals & Nature',
      travel_places: 'Travel & Places',
      activities: 'Activities',
      objects: 'Objects',
      symbols: 'Symbols',
      recently_used: 'Recently used',
    },
    pick_emoji: 'Choose emoji',
    button_send: 'Send',
  },
  history_section: {
    history: 'History',
    chat_history: 'Chat history',
    lead: 'Lead',
    no_lead: 'No lead',
    intervened: 'Intervened',
    load_more: 'Load more',
    allSite: 'All sites',
    operatorFilterTitle: 'The conversation intervened',
    channelFilterTitle: 'Channel',
    conditionFilterTitle: 'Condition',
    infOnDurationChats: 'chats from the last 7 days.',
    form: {
      searchVisitor: 'Search by name',
      from: 'from',
      to: 'to',
      cleanFilters: 'Clean Filters',
      applyFilters: 'Apply Filters',
    },
  },
  no_chats_available_history: {
    title: "You don't have chats to review yet",
    legend: "Here you'll see all the conversations of your visitors with Cliengo.",
    checklist: {
      label: "You' ll see the chats of the contacts that:",
      points: ['Chatted with the chatbot', 'The operators intervened', "Didn't become leads"],
    },
  },
  no_chats_available_history_search: {
    title: "We can't find chats that match your search.",
    legend: 'Try other information from the conversation',
  },
  empty_state_section: {
    no_chats_available: "You don't have any chats available",
    see_visitors_chatting: "Here you'll see all the visitors chatting with the chatbot.",
    traffic_advise: 'We advise you to increase the traffic on your site.',
    talk_to_visitor: 'Want to talk to your visitors?',
    into_chats_no_operators: 'You can go into the chats',
    no_operator_link: 'without operators.',
    no_visitors_chatting_now: 'No visitors chatting with operators',
    no_visitors_logged_now: 'There are no visitors logged in at this time',
    no_chats_progress: "You don't have chats in progress",
    want_talk_clients_question: 'Do you want to talk to your clients?',
    see_visitors_chat: "Here you'll see all the visitors chatting with the chatbot and the operators.",
    want_talk_visitors: 'Do you want to talk to your visitors?',
    get_chat_without_operator: 'Enter the chat of any of the visitors who are',
    no_chats_to_see: {
      here_show_all_chats:
        "Here you'll see all the conversations of your visitors with Cliengo.You' ll see the chats of the contacts that:",
      chat_with_bot: 'Chatted with the chatbot',
      operators_intervened: 'The operators intervened',
      not_become_lead: "Didn't become leads",
    },
    no_chats_search: 'Check filter selections or try another search.',
    connect_chatbot: 'Connect your chatbot',
    want_talk_clients: "a website and answer your visitors' queries.",
    advise_increase_traffic: '💡 We advise you to increase the traffic on your site.',
    no_message_error: {
      error_title: 'Oops! I think there was an error',
      error_content: "We couldn't find the chat you were looking for.",
    },
  },
  visitor_context: {
    tags: {
      title: 'Tags',
      SHOULD_INTERVENE: 'To intervene',
    },
    header: {
      title: 'Visitor details',
    },
    general_information: {
      title: 'General Information',
      empty_state_section: '',
      tooltip_content: 'General customer information',
    },
    bot_response_module: {
      title: 'Bot response',
      empty_state_section: 'has not selected any option',
      tooltip_content: 'Options they selected while interacting with the bot',
    },
    navigation_information: {
      title: 'Navigation information',
      empty_state_section: '',
      tooltip_content: 'Navigation information',
      keynameSite: 'Sitio',
      keynameURL: 'URL',
    },
    browsing_history: {
      title: 'Browsing history',
      empty_state_section: '',
      tooltip_content: 'Sites visited so far',
    },
    visitor_inquiries: {
      title: 'Visitor inquiries',
      empty_state_section: "We haven't detected any inquiries yet",
      tooltip_content: 'We extracted from the conversation some queries that may be of interest to you',
    },
  },
};
