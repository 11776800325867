import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Tabs, SearchSelect } from 'cliengo-ui';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

import {
  MainContainerTable,
  MasterDetailContainer,
  NewSlideContainer,
} from '../../GlobalStyles/Layouts';
import ConversationDetails from '../../Components/Panels/ConversationDetails';
import TagSelectorComponent from '../../Components/TagSelector/TagSelector.component';
import analytics, { EVENTS } from '../../services/analytics';
import {
  executeOnlyOneTime,
  getLocalStorage,
  setLocalStorage,
  deleteLocalStorage,
} from '../../utils';
import { translate } from '../../services/translate';
import { VisitorContextFeatureSelector } from '../../Components/VisitorContext/VisitorContextFeatureSelector';
import { hasLabEcom } from '../../labs/hasLabEcom';
import { Convs } from '../../Interfaces/AllChats';
import LittenConversationDetails from '../../Components/Panels/ConversationDetails/LittenConversationDetails/LittenConversationDetails';
import { RootState } from '../../Interfaces/Default';

import {
  StyledSearchSelect,
  ContentTitleTab,
  SearchWrapper,
  SearchWrapperInput,
  EmptyStateButton,
  TextInputLive,
} from './allChats.style';
import TableAllChats from './Table.component';
import {
  AllChatsComponentProps,
  GenericTablePropsType,
  SitesOptionsType,
  TabType,
  BasicType,
} from './AllChatsTypes';
import filterByTags from './filterByTag';

const useQuery = () => new URLSearchParams(useLocation().search);

const AllChatsComponent = ({
  isOpenSlide,
  chats,
  loading,
  hasScriptInstalled,
  slidePanel,
  sitesOptions,
  getTableData,
  goTo,
  socket,
  OnTabPessCallback,
  preselectedTab,
  websites,
  isLittenChatPreviewOpen,
  ...rowProps
}: AllChatsComponentProps) => {
  const auth = useSelector((state: RootState) => state.auth);
  const { pathname } = useLocation();

  const TitleTab = ({ name, number }: BasicType) => (
    <ContentTitleTab>
      {`${name} `}
      <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
        {`(${number})`}
      </span>
    </ContentTitleTab>
  );

  const trackSearchEventOnce = executeOnlyOneTime((query: string) => {
    analytics.trackEvent({
      name: EVENTS.CHAT_SEARCH,
      label: query,
    });
  });

  const GenericTable = ({
    items,
    noData,
    showAsigned,
  }: GenericTablePropsType) => {
    const textInput = useRef<HTMLInputElement>(null);
    const [filtered, setFiltered] = useState(items);
    const history = useHistory();
    const query = useQuery();
    const querySearch = getLocalStorage('search') as string; // por si trae querys
    const queryWeb = useQuery().get('web'); // para ver si abre detalles o no
    const [selectedOpt, setselectedOpt] = useState(queryWeb ?? 'all');
    const [chatFilter, setchatFilter] = useState(querySearch ?? '');

    const addCounterToWebsitesList = () => {
      const chatsByWebsites: { all: number; [key: string]: number } = {
        all: items.length,
      };
      items.forEach(
        (item) => (chatsByWebsites[item.webId] = (chatsByWebsites[item.webId] || 0) + 1),
      );
      return sitesOptions.map(({ value, label }: SitesOptionsType) => ({
        value,
        label: `${label} (${chatsByWebsites[value] ?? 0})`,
        website: value === 'all' ? value : label,
      }));
    };
    const [checkEmpty, setCheckEmpty] = useState(false);
    const [websites, setWebsites] = useState(addCounterToWebsitesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setWebsites(addCounterToWebsitesList()), []);

    const trackSelectedWebsite = (selectedWebsite: string) => {
      analytics.trackEvent({
        name: EVENTS.SITE_FILTER,
        label: selectedWebsite,
      });
    };

    const filterData = useCallback(
      (selected = 'all', query = '') => {
        const queryLowerCase = String(query).toLowerCase();

        const filteredBySite = items.filter(
          (chat) => selected === 'all' || selected === chat?.webId,
        );
        const filteredData = filteredBySite.filter((itm) => {
          const visitorName: string = itm.table[0].label.props.children;
          const operatorName: string = itm.table[4].label.props.children;

          return (
            visitorName?.toLowerCase().includes(queryLowerCase)
            || operatorName?.toLowerCase().includes(queryLowerCase)
          );
        });
        return filteredData;
      },
      [items],
    );

    const byWeb = (selected: string) => {
      query.delete('tag');
      setselectedOpt(selected);

      if (selected !== 'all') {
        query.set('web', selected);
      } else {
        query.delete('web');
      }
      history.replace({ search: query.toString() });
    };

    useEffect(() => {
      const filteredData = filterData(selectedOpt, chatFilter);
      setFiltered(filteredData);

      const { website } = websites.find((ws) => ws.value === selectedOpt) ?? {
        website: 'all',
      };
      trackSelectedWebsite(website);
      if (filteredData.length === 0) {
        setCheckEmpty(true);
      }
    }, [selectedOpt]);

    const byInputChange = (selected: string) => {
      setchatFilter(selected);

      if (selected !== '') {
        setLocalStorage('search', selected);
      } else {
        deleteLocalStorage('search');
      }
    };

    useEffect(() => {
      setCheckEmpty(false);
      const filteredItems = filterData(selectedOpt, chatFilter);
      if (chatFilter.length > 0) {
        trackSearchEventOnce(chatFilter);
        if (filteredItems.length === 0) {
          setCheckEmpty(true);
        }
      }
      setFiltered([...filteredItems]);
    }, [chatFilter, textInput.current]);

    return (
      <div
        className="is-flex is-flex-direction-column is-full-height"
        style={{ height: '70vh' }}
      >
        <StyledSearchSelect>
          <SearchWrapper
            style={{
              display: hasLabEcom() ? 'grid' : 'flex',
              gridTemplateColumns: '1fr 1fr',
              gap: '1rem',
            }}
          >
            <SearchSelect
              options={websites}
              placeholder={translate('visitors_section.select_a_site')}
              value={selectedOpt}
              style={{ margin: '18px 0', width: '380px' }}
              onChange={(selected: string) => byWeb(selected)}
            />
            <TagSelectorComponent />
          </SearchWrapper>
          <SearchWrapperInput>
            <i className="icon-search1" />
            <TextInputLive
              id="allChatsSearchInput"
              callback={(e: string) => byInputChange(e)}
              className="conversation-search-input"
              value={chatFilter}
              placeholder={translate('visitors_section.search_chat')}
              ref={textInput}
            />
          </SearchWrapperInput>
        </StyledSearchSelect>

        {/* LA TABLA DE LIT */}
        <TableAllChats
          {...rowProps}
          itemsTableLit={
            filterByTags(filtered, query.get('tags') ?? '') as Convs[]
          }
          loading={loading}
          noData={noData}
          checkEmpty={checkEmpty}
          showAsigned={showAsigned}
          // preselectedConver={preselectedConver}
        />
      </div>
    );
  };

  // EL CONTENIDO DE CADA TAB
  const contentTab = [
    {
      id: 'available',
      title: (
        <TitleTab
          name={translate('visitors_section.without_operators')}
          number={chats.available.length || 0}
        />
      ),
      content: ({
        setTab,
      }: {
        setTab: React.Dispatch<React.SetStateAction<TabType>>;
      }) => (
        <GenericTable
          items={getTableData(chats.available)}
          noData={getEmptyStateInfo(setTab).available}
          showAsigned={false}
        />
      ),
    },
    {
      id: 'taken',
      title: (
        <TitleTab
          name={translate('visitors_section.chatting')}
          number={chats.taken.length || 0}
        />
      ),
      content: ({
        setTab,
      }: {
        setTab: React.Dispatch<React.SetStateAction<TabType>>;
      }) => (
        <GenericTable
          items={getTableData(chats.taken)}
          noData={getEmptyStateInfo(setTab).taken}
          showAsigned
        />
      ),
    },
    {
      id: 'all',
      title: (
        <TitleTab
          name={translate('visitors_section.all')}
          number={chats.taken.length + chats.available.length || 0}
        />
      ),
      content: ({
        setTab,
      }: {
        setTab: React.Dispatch<React.SetStateAction<TabType>>;
      }) => (
        <GenericTable
          items={getTableData(chats.available.concat(chats.taken))}
          noData={getEmptyStateInfo(setTab).all}
          showAsigned
        />
      ),
    },
  ];

  // OBTIENE LA INFO DEL EMPTY STATE SI TIENE O NO SCRIPT INSTALADO
  const getEmptyStateInfo = (
    setTab: React.Dispatch<React.SetStateAction<TabType>>,
  ) => {
    if (hasScriptInstalled) {
      return {
        available: {
          img: 'https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/general.svg',
          title: translate('empty_state_section.no_chats_available'),
          subtit: translate('empty_state_section.see_visitors_chatting'),
          tip: translate('empty_state_section.advise_increase_traffic'),
        },
        taken: {
          img: 'https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/tabs.svg',
          title: translate('empty_state_section.no_visitors_chatting_now'),
          subtit: (
            <strong>{translate('empty_state_section.talk_to_visitor')}</strong>
          ),
          tip: (
            <span style={{ fontSize: '16px' }}>
              {translate('empty_state_section.into_chats_no_operators')}
              <EmptyStateButton onClick={() => setTab(contentTab[0])}>
                {` ${translate('empty_state_section.no_operator_link')}`}
              </EmptyStateButton>
            </span>
          ),
        },
        all: {
          img: 'https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/general.svg',
          title: translate('empty_state_section.no_visitors_logged_now'),
          subtit: translate('empty_state_section.see_visitors_chat'),
          tip: translate('empty_state_section.advise_increase_traffic'),
        },
      };
    }
    return {
      available: {
        img: 'https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/general.svg',
        title: translate('empty_state_section.want_talk_clients_question'),
        subtit: (
          <>
            <EmptyStateButton
              onClick={() => goTo('/chatbot/installation-code')}
            >
              {translate('empty_state_section.connect_chatbot')}
            </EmptyStateButton>
            {' '}
            {translate('empty_state_section.want_talk_clients')}
          </>
        ),
        tip: '',
      },
      taken: {
        img: 'https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/tabs.svg',
        title: translate('empty_state_section.want_talk_clients_question'),
        subtit: (
          <>
            <EmptyStateButton
              onClick={() => goTo('/chatbot/installation-code')}
            >
              {translate('empty_state_section.connect_chatbot')}
            </EmptyStateButton>
            {' '}
            {translate('empty_state_section.want_talk_clients')}
          </>
        ),
        tip: '',
      },
      all: {
        img: 'https://res.cloudinary.com/hbrrdozyj/image/upload/v1616081892/cliengo/general.svg',
        title: translate('empty_state_section.want_talk_clients_question'),
        subtit: (
          <>
            <EmptyStateButton
              onClick={() => goTo('/chatbot/installation-code')}
            >
              {translate('empty_state_section.connect_chatbot')}
            </EmptyStateButton>
            {' '}
            {translate('empty_state_section.want_talk_clients')}
          </>
        ),
        tip: '',
      },
    };
  };

  /**
   * `trackActiveTabEvent` sends the live_tab event.
   * @param {'available'|'taken'|'all'} tab - the pressed tab.
   */
  const trackActiveTabEvent = (tab: string) => {
    analytics.trackEvent({
      name: EVENTS.ACTIVE_TAB,
      label: tab,
    });
  };

  const OnPressed = useCallback(
    (tab: TabType) => {
      trackActiveTabEvent(tab.id);
      OnTabPessCallback(tab.id);
    },
    [OnTabPessCallback],
  );

  const onPressWhiteBox = () => {
    OnTabPessCallback('white');
  };

  function trackEventsOnFirstComponentRender() {
    analytics.trackPage(EVENTS.VISITORS);
    trackActiveTabEvent(contentTab[0].id);
  }
  useEffect(trackEventsOnFirstComponentRender, []);

  const { featureLittenMyChatsLive778, featureLiveCrmSyncAssignment } = useFlags();

  const isAdmin = auth?.user?.permissions?.includes('admin');

  const chatId = useQuery().get('chatId');

  const isLead = chatId && chats.available
    .concat(chats.taken)
    .some((chat) => chat.id === chatId && (chat.visitorEmail || chat.visitorPhone));

  const hasHiddenControls = (pathname.includes('visitors'))
    && isAdmin
    && !isLead
    && (featureLiveCrmSyncAssignment as boolean);

  const activeConversationDetails = useMemo(
    () => (featureLittenMyChatsLive778 ? (
      <LittenConversationDetails hiddenControls={hasHiddenControls} />
    ) : (
      <ConversationDetails
        socket={socket}
        hideFooter={hasHiddenControls}
        websites={websites}
      />
    )),
    [featureLittenMyChatsLive778, hasHiddenControls, socket, websites],
  );

  return (
    <MasterDetailContainer>
      {/* CONTENIDO PRINCIPAL */}
      <ChatsContainer>
        <WhiteBox onClick={() => onPressWhiteBox} />
        <StyledMainContainer
          isOpenSlide={isOpenSlide}
          isLittenChatPreviewOpen={isLittenChatPreviewOpen}
        >
          <Tabs
            contentTab={contentTab}
            classTabHeader="StyledHeader"
            classActiveTab="activeTab"
            classTabContent="StyledContentTab"
            pressTabCallback={OnPressed}
            preActivated={preselectedTab}
          />
        </StyledMainContainer>
      </ChatsContainer>

      {/* SLIDE OUT PANEL */}
      <NewSlideContainer isOpenSlide={isOpenSlide}>
        {slidePanel?.content?.key === 'actions' ? (
          activeConversationDetails
        ) : (
          <VisitorContextFeatureSelector />
        )}
      </NewSlideContainer>
    </MasterDetailContainer>
  );
};

const WhiteBox = styled.div`
  height: 100%;
  width: 90%;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  @media screen and (min-width: 1080px) {
    display: block;
  }
`;

const ChatsContainer = styled.div`
  margin-left: 5%;
  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
`;

const StyledMainContainer = styled(MainContainerTable)`
  padding-top: 30px;
  .StyledHeader {
    width: 414px;
  }
  .activeTab {
    font-weight: bold;
  }
  .StyledContentTab {
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    height: calc(100% - 84px);
    overflow: auto;
  }
`;

export default AllChatsComponent;
