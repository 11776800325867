import React from 'react';
import { components } from 'react-select';

import Indicator from './DropdownIndicator.style';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Indicator className="icon-expand-more" />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
