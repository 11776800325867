import styled from 'styled-components';

export const TagSelectorSkeletonContianer = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 80px;
  .Tooltip-Tip {
    text-align: center;
  }
  .tag-tooltip-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 21px;
      color: #7D60F5;
    }
  }
`;

export const TagSelectorSkeleton = styled.div`
  border: 1px solid #e0e0e0;
  opacity: 0.7;
  cursor: not-allowed;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 0 10px;
  & > i {
    font-size: 18px;
  }
`;

export default {};
