import {
  IData,
  IWebsite,
  LOGOUT,
  IUser,
  SET_ALL_USERS,
  SET_CURRENT_USER,
  SET_WEBSITES,
} from './Types';

export const setWebsites = (websites: IWebsite) => ({
  type: SET_WEBSITES,
  payload: websites,
});

export const setCurrentUser = (data: IData) => ({
  type: SET_CURRENT_USER,
  payload: data,
});

export const setUsers = (users: IUser) => ({
  type: SET_ALL_USERS,
  payload: users,
});

export const logout = () => ({
  type: LOGOUT,
});
