import styled from 'styled-components';
import Select from 'react-select';

const TagsSelector = styled(Select)`
  .tags-select__indicator-separator {
    display: none;
  }
  .tags-select__menu {
    z-index: 999;
  }
  .tags-select__indicator {
    color: #515151;
  }
`;

export default TagsSelector;
