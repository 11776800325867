import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { closeSlide } from '../../../Store/actions/slide';
import {
  getMessageStructure,
  useGetMessages,
} from '../../../customHooks/getMessages';
import { socketActions } from '../../../services/socketeerConnections';
import analytics, { EVENTS } from '../../../services/analytics';
import { clearMessages } from '../../../Store/Message/Actions';
import { setLocalStorage } from '../../../utils';
import assignConversation from '../../../services/visitors';

import ConversationDetailsView from './ConversationDetails.component';

const useQuery = () => new URLSearchParams(useLocation().search);

const ConversationDetails = ({
  closeSlide,
  chatInfo,
  idChat,
  user,
  isOpenSlide,
  socket,
  messages: newMessages,
  clearMessages,
  hideFooter,
  websites,
  users,
  convs = [],
}) => {
  const { featureLittenMyChatsLive778 } = useFlags();

  const [isChatting, setIsChatting] = useState('');
  const [selectedChat, setSelectedChat] = useState(null);
  const { messages, isLoading, setMessages } = useGetMessages(idChat, user);
  const [showModal, setShowModal] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(true);
  const [currentStatusSession, setCurrentStatusSession] = useState('');
  const history = useHistory();
  const messagesRef = useRef(null); // referencia a la ventana donde se ven los mnesajes
  const goTo = (params) => history.push(params);

  useEffect(() => {
    setShowWarningDialog(!(localStorage.getItem('showWarningDialog') === 'false'));
  }, [isLoading]);

  useEffect(() => {
    if (newMessages && newMessages.length > 0 && newMessages[newMessages.length - 1].conversationId === idChat) {
      setMessages([...messages, getMessageStructure(newMessages[newMessages.length - 1], user)]);
    }
  }, [newMessages]);

  useEffect(() => {
    if (!isOpenSlide) clearMessages();
  }, [isOpenSlide, clearMessages]);

  useEffect(() => {
    const win = messagesRef?.current;
    win.scrollTo(0, win.scrollHeight);
  }, [messages, isLoading]);

  useEffect(() => {
    const takenChat = chatInfo.taken.find((chat) => chat.id === idChat);
    setSelectedChat(takenChat);
    if (takenChat) {
      const participant = takenChat.participants?.find((e) => e.type === 'user');
      if (participant.id !== user.id) {
        setIsChatting(participant.name?.split(' ')[0] || ' ');
      } else setIsChatting('');
    } else if (isChatting) setIsChatting('');
  }, [idChat]);

  useEffect(() => {
    if (convs.length > 0) {
      const conv = convs?.find((conv) => conv.id === idChat);
      setCurrentStatusSession(conv.sessionStatus);
    }
  }, [idChat]);

  const closeModal = () => setShowModal(false);

  function trackinterveningChatEvent(dontShowMessageAgain) {
    analytics.trackEvent({
      name: EVENTS.ENTER_CHAT,
      label: dontShowMessageAgain || '',
    });
  }
  const sendConverseEvent = () => {
    trackinterveningChatEvent(!showWarningDialog);
    if (!showWarningDialog) localStorage.setItem('showWarningDialog', `${showWarningDialog}`);
    else localStorage.removeItem('showWarningDialog');
    closeModal();
    interruptChat();
  };

  const interruptChat = async () => {
    const conversation = {
      id: idChat,
      websiteId: selectedChat?.websiteId,
      companyId: user.companyId,
    };
    const message = {
      action: socketActions.SEND_MESSAGE,
      body: socketActions.SHUT_UP,
      conversationId: idChat,
      message: {
        text: socketActions.SHUT_UP,
        type: 'text/plain',
      },
    };

    if (featureLittenMyChatsLive778) {
      assignConversation(idChat, user.id);
    }
    socket.sendMessage(message);
    socket.sendAction('updateChat', conversation);

    setLocalStorage('mine', idChat);
    goTo({ pathname: '/live/active/mine', search: `?chatId=${idChat}` });
  };

  function trackAskingToChatEvent() {
    const event = {
      name: EVENTS.GO_TO_CHAT,
      category: 'live',
    };
    analytics.trackEvent(event);
  }

  // funcion para ver en el localStorage si tiene seteado que no pregunte mas por la advertencia de intervenir
  const openConditionalModal = () => {
    trackAskingToChatEvent();

    const conversation = [...chatInfo.available, ...chatInfo.taken].find((e) => e.id === idChat);
    if (conversation.participants?.find((e) => e.type === 'user' && e.id === user.id)) {
      goTo({ pathname: '/live/active/mine', search: `?chatId=${idChat}` });
    } else if (showWarningDialog) setShowModal(true);
    else interruptChat();
  };

  const queryChatId = useQuery().get('chatId');
  const sliderCloser = () => {
    closeSlide();
    if (queryChatId) {
      history.replace({ search: '' });
    }
  };

  return (
    <ConversationDetailsView
      data={messages}
      chatInfo={chatInfo}
      idChat={idChat}
      chatingUser={isChatting}
      showModal={showModal}
      closeModal={closeModal}
      openModal={openConditionalModal}
      closeSlide={sliderCloser}
      sendConverseEvent={sendConverseEvent}
      isLoading={isLoading}
      dontShowAgain={!showWarningDialog}
      messagesRef={messagesRef}
      dontShowAgainCallback={(v) => setShowWarningDialog(!v)}
      hideFooter={hideFooter}
      websites={websites?.sites}
      users={users}
      currentStatusSession={currentStatusSession}
    />
  );
};

const mapStateToProps = (state) => ({
  chatInfo: state.chatInfo,
  idChat: state.slidePanel?.content?.id,
  user: state.auth?.user,
  users: state.users,
  companyId: state.auth.account.id,
  isOpenSlide: state.slidePanel.open,
  messages: state.messages,
});

export default connect(mapStateToProps, { closeSlide, clearMessages })(ConversationDetails);
