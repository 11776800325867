import {
  CLOSE_LITTEN_SLIDE,
  CLOSE_SLIDE,
  OPEN_LITTEN_SLIDE,
  OPEN_SLIDE,
  SET_APP_SLIDE, SET_LITTEN_SLIDE,
} from '../actions/types';

const initialState = {
  open: false,
  content: null,
  isFirstLoad: true,
  isLittenPanelOpen: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let newPayload = Object.assign(initialState, payload);
  switch (type) {
    case SET_APP_SLIDE:
      return ({ ...state, ...newPayload, isFirstLoad: false });
    case OPEN_SLIDE:
      return ({ ...state, open: true });
    case CLOSE_SLIDE:
      return { ...state, content: null, open: false };
    case SET_LITTEN_SLIDE:
      return ({ ...state, ...newPayload });
    case OPEN_LITTEN_SLIDE:
      return ({ ...state, isLittenPanelOpen: true });
    case CLOSE_LITTEN_SLIDE:
      return ({ ...state, isLittenPanelOpen: false });
    default:
      return state;
  }
}
