import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Button, ConversationBubble } from 'cliengo-ui';

import config from '../../../services/config';
import { TitleChatWindow } from '../../../GlobalStyles';
import {
  MainContainerPanel, SubcontainerPanel, ChatHeaderPanel, ChatBodyPanel, ChatFooterPanel,
} from '../../../GlobalStyles/Layouts';
import ModalInterveneConversation from '../../Modals/InterveneConversation';
import { ConversationMock } from '../../ChatsComponents';
import { translate } from '../../../services/translate';
import EmptyTable from '../../EmptyTable/EmptyTable';
import { Images } from '../../../Constants';
import WarningMessage from '../../WarningMessage/WarningMessage';

import {
  Icon, ContactTexting, ChatBodyContainer,
  WarningContainer,
  ConversationMeta,
} from './ConversationDetails.styles';

const ConversationDetailsView = ({
  data,
  chatingUser,
  closeSlide,
  chatInfo,
  idChat,
  sendConverseEvent,
  openModal,
  closeModal,
  showModal,
  isLoading,
  dontShowAgain,
  dontShowAgainCallback,
  messagesRef,
  hideFooter,
  websites,
  users,
  currentStatusSession,
  ...props
}) => {
  const [currentChat, setCurrentChat] = useState({});
  const [currentWebsite, setCurrentWebsite] = useState({});
  const [mappedMessages, setMappedMessages] = useState([]);

  const { pathname } = useLocation();
  const splittedUrl = pathname.split('/');
  const isHistory = splittedUrl[splittedUrl.length - 1] === 'history';
  const hasExternalOperator = useRef(false);

  const getConversation = async (converId, isItHistory) => {
    let conversation;

    try {
      if (isItHistory) {
        const response = await axios.get(
          `${config.urls.API_URL}/conversations/${converId}`,
          { withCredentials: true },
        );

        if (response.status === 200) conversation = response.data;
      } else {
        const onlineConversations = [...chatInfo.available, ...chatInfo.taken];
        conversation = onlineConversations.find((chat) => chat.id === converId);
      }
    } catch (error) {
      console.error(`error getting the chat ${error}`);
    }

    setCurrentChat(conversation);
  };

  useEffect(() => {
    getConversation(idChat, isHistory);
  }, [idChat]);

  useEffect(() => {
    setMappedMessages(mapMessages());
  }, [currentChat, data]);

  const noInfoWrapper = () => {
    const dataEmpty = {
      img: Images.messagesErrorInConversation,
      title: translate('empty_state_section.no_message_error.error_title'),
      subtit: translate('empty_state_section.no_message_error.error_content'),
    };

    return <EmptyTable data={dataEmpty} />;
  };

  const account = useSelector((state) => state.auth?.account);
  const canIntervene = () => !chatingUser
    || (
      account.labs.force_intervention
      && account.labs.whatsapp_premium
      && (
        currentChat?.channel === 'WHATSAPP'
      )
    );

  const getParticipantName = (participantId, options) => {
    const found = options.find((user) => user.id === participantId);
    return found?.name;
  };
  const mapMessages = () => {
    const chatSite = websites?.find((site) => site.id === currentChat?.websiteId);
    setCurrentWebsite(chatSite);

    // Esta trayendo el array de mensajes de forma invertida
    // Genero una copia del array data y lo invierto
    const reversedData = data.slice(0).reverse();

    if (reversedData.length > 0) {
      return reversedData.map((e, index) => {
        if (e.type === 'externalOperator') {
          hasExternalOperator.current = true;
          e.name = chatSite ? `@${chatSite?.title}` : 'Desconocido';
        }
        e.name = e.name !== 'Desconocido' ? e.name : `@${chatSite?.title}`;
        if (e.type === 'user') e.name = getParticipantName(e.sender, users) ?? chatingUser;
        return (hideFooter
          ? (
            <div key={e.id}>
              {index === 0 && (
                <ConversationMeta>
                  {e.sendAt}
                  <br />
                  {e.name}
                  {' '}
                  {translate('my_chats_section.interacctions_with_conversation.joined_chat')}
                </ConversationMeta>
              )}
              <ConversationBubble {...e} sentAt={e.sendAt} key={e.id} channel={currentChat?.channel} />
              {index + 1 === reversedData.length && currentStatusSession !== 'ACTIVE' && (
                <ConversationMeta>
                  {translate(
                    'my_chats_section.interacctions_with_conversation.last_message',
                  )}
                  <br />
                  {e.sendAt}
                </ConversationMeta>
              )}
            </div>
          )
          : <ConversationBubble {...e} sentAt={e.sendAt} key={e.id} channel={currentChat?.channel} />);
      });
    }

    return noInfoWrapper();
  };

  return (
    <MainContainerPanel className={props.className} id="conversationDetailsPanel">
      <SubcontainerPanel>
        {/* HEADER DEL PANEL */}
        <ChatHeaderPanel>
          <TitleChatWindow>Chat</TitleChatWindow>
          <Icon className="icon icon-close-panel" onClick={closeSlide} />
        </ChatHeaderPanel>

        {/* BODY DEL PANEL */}
        <ChatBodyContainer isExternalOperator={hasExternalOperator}>
          <ChatBodyPanel withFooter ref={messagesRef}>
            {
              isLoading
                ? <ConversationMock isLoading />
                : (mappedMessages)
            }
          </ChatBodyPanel>
          <WarningContainer>
            <WarningMessage
              isExternalOperator={hasExternalOperator.current && !isHistory}
              websiteName={currentWebsite?.title}
              height="80px"
            />
          </WarningContainer>
        </ChatBodyContainer>
        {/* FOOTER DEL PANEL */}
        {!hideFooter
          && (
            <ChatFooterPanel>
              {canIntervene()
                ? (
                  <Button
                    title={translate('visitors_section.chat_preview.chat')}
                    callback={openModal}
                    disabled={isLoading}
                    className="button-converse"
                  />
                )
                : (
                  <ContactTexting>
                    <i className="icon-info-circle" />
                    <span className="userName">{chatingUser}</span>
                    {translate('visitors_section.chat_preview.is_chatting_with_contact')}
                  </ContactTexting>
                )}
            </ChatFooterPanel>
          )}
      </SubcontainerPanel>

      {/* MODAL PARA INTERVENIR LA CONVERSACION */}
      <ModalInterveneConversation
        closeModal={closeModal}
        showModal={showModal}
        sendConverseEvent={sendConverseEvent}
        dontShowAgainCallback={dontShowAgainCallback}
        dontShowAgain={dontShowAgain}
        idToInsertIn="conversationDetailsPanel"
        containerClass="containerModal"
      />
    </MainContainerPanel>
  );
};

export default ConversationDetailsView;
