import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { translate } from '../../../services/translate';
import EmptyTable from '../../EmptyTable/EmptyTable';
import { setSelectedConversation } from '../../../Store/actions/visitorContext.actions';
import { setSort } from '../../../Store/Sort/Actions';

import {
  TableWrapper,
  TrBody,
  Data,
  SkeletonPulse,
  EmptyState,
  Label,
  TableCell,
  SortButton,
} from './styles';

const generateKey = () => `${Math.random() * 1000}_${new Date().getTime()}`;

const TableLit = ({
  header,
  items,
  loading,
  noData,
  className,
  loadingItemsMock,
  sortCallback,
  rowSelectedCallback,
  rowSelected,
  checkEmpty,
  isOpenSlide,
  isLittenPanelOpen,
}) => {
  const statusSort = useSelector((state) => state.sort);
  const cols = (window.innerWidth > 420 && header) || items[0] || new Array(3).fill({});
  // const [statusSort, setStatusSort] = useState(sortState);
  useEffect(() => {
    sortCallback(statusSort);
  }, [statusSort.key, statusSort.value]);

  const setSortColumn = async (data) => {
    let setValue = 'asc';
    if (statusSort.value === 'asc') {
      setValue = 'desc';
    }

    setSort(
      {
        key: data,
        value: setValue,
      },
    );
  };

  const setSelectedRow = (index, row, field) => {
    rowSelectedCallback({
      key: field.key,
      selectedIndex: index,
      id: row.id,
    });
    setSelectedConversation(row.webId, row.id);
  };

  const NoInfoWrapper = () => {
    const data = {
      img: noData.img,
      title: translate('visitors_section.no_chats_found'),
      subtit: translate('visitors_section.review_filter'),
    };

    return <EmptyTable data={checkEmpty ? data : noData} />;
  };

  function sortRowItem(items) {
    if (items.length > 0) {
      return header
        .map((h) => (h.key === 'actions' ? { ...h, key: 'buttonAction' } : h))
        .map((h) => items.find((i) => i.key === h.key));
    }
    return [];
  }

  const TableBody = ({ items }) => {
    if (items.length === 0) {
      return (
        <>
          {[...Array(loadingItemsMock)].map(() => (
            <tr key={Math.random() * 10000}>
              {cols.map((sk) => (
                <Data key={Math.random() * 10000} mobile={sk.mobile}>
                  <EmptyState />
                </Data>
              ))}
            </tr>
          ))}
        </>
      );
    }
    return items.map((row, indexrow) => (
      <TrBody key={generateKey()} selected={row.id === rowSelected}>
        {sortRowItem(row.table)
          .filter((_, i) => header[i].show)
          .map((data, i) => {
            const openSlide = (
              (isOpenSlide || isLittenPanelOpen) && ((header.length > 5
              && (i === 1 || i === 4)) || (i === 1))
            );
            if (openSlide) return false;
            const filterHeader = header.filter((item) => item.show);
            return (
              <Data
                mobile={columnOrderMobile[i]?.value}
                fromTBody
                style={data?.style}
                key={generateKey()}
                deleted={data?.deleted}
                onClick={() => setSelectedRow(indexrow, row, filterHeader[i])}
              >
                <Label mobile>
                  {row.table[columnOrderMobile[i]?.indice]?.labelMobile
                  || row.table[columnOrderMobile[i]?.indice]?.label}
                </Label>
                <TableCell>{data?.label}</TableCell>
              </Data>
            );
          })}
      </TrBody>
    ));
  };

  const columnOrderMobile = header
    .filter((first) => first.show)
    .filter((second) => second.orderMobile >= 0)
    .sort((a, b) => a.orderMobile - b.orderMobile);
  return (
    <div
      className={[
        items?.length >= 4 ? 'is-flex-1 is-overflow-auto' : 'is-relative',
      ].join(' ')}
      style={{ overflowX: 'auto' }}
    >
      {items.length === 0 && <NoInfoWrapper />}
      <TableWrapper className={className}>
        {header && (
          <thead>
            <tr key={generateKey()}>
              {header
                .filter((o) => o.show)
                .map((h, i) => {
                  const openSlide = (
                    (isOpenSlide || isLittenPanelOpen) && ((header.length > 5
                    && (i === 1 || i === 4)) || (i === 1))
                  );
                  if (openSlide) return false;
                  return (
                    <Data
                      mobile={columnOrderMobile[i]?.value}
                      key={h.key}
                      borderLeft={i === 0}
                      borderRight={
                        i === header.filter((o) => o.show).length - 1
                      }
                    >
                      {h.key !== 'actions' && h.key !== 'tags' ? (
                        <SortButton pointer onClick={() => setSortColumn(h.key)}>
                          <Label mobile key={generateKey()}>
                            {columnOrderMobile[i]?.value}
                          </Label>
                          <Label key={generateKey()}>{h.value}</Label>

                          {h.sort && (
                            <i
                              key={generateKey()}
                              className={`icon icon-${
                                statusSort.key === h.key
                                  ? `sort-${statusSort.value}`
                                  : 'sort'
                              }`}
                            />
                          )}
                        </SortButton>
                      ) : (
                        <SortButton>
                          <Label mobile key={generateKey()}>
                            {columnOrderMobile[i]?.value}
                          </Label>
                          <Label key={generateKey()}>{h.value}</Label>
                        </SortButton>
                      )}
                    </Data>
                  );
                })}
            </tr>
          </thead>
        )}

        <tbody>
          {loading ? (
            [...Array(loadingItemsMock)].map(() => (
              <tr key={Math.random() * 10000}>
                {cols.map((sk) => (
                  <Data key={Math.random() * 10000} mobile={sk.mobile}>
                    <SkeletonPulse
                      key={Math.random() * 10000}
                      randomOffset={`${Math.random() * 200}px`}
                    />
                  </Data>
                ))}
              </tr>
            ))
          ) : (
            <TableBody items={items} />
          )}
        </tbody>
      </TableWrapper>
    </div>
  );
};

TableLit.propTypes = {
  header: PropTypes.arrayOf(PropTypes.object),
  items: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  className: PropTypes.string,
  loadingItemsMock: PropTypes.number,
  sortCallback: PropTypes.func,
  rowSelectedCallback: PropTypes.func,
  rowSelected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

TableLit.defaultProps = {
  items: [],
  header: null,
  loading: false,
  className: null,
  loadingItemsMock: 15,
  sortCallback: () => {},
  rowSelectedCallback: () => {},
  rowSelected: null,
};

export default TableLit;
