import LaunchDarklyClientSdk, { LDClient } from 'launchdarkly-js-client-sdk';

import { User } from '../Interfaces/AllChats';

import config from './config';

let launchDarklyClient: LDClient;
type updateFlagsType = {
  [key: string]: { previous: boolean; current: boolean };
};
let updatedFlags: updateFlagsType = {};

export const initializeLDClient = (userData: User) => {
  launchDarklyClient = LaunchDarklyClientSdk.initialize(config.launchdarkly.key, {
    key: userData.id,
    name: userData.name,
    email: userData.email,
    custom: {
      companyId: userData.companyId,
    },
  });

  launchDarklyClient.on('change', (newData: updateFlagsType) => {
    updatedFlags = { ...updatedFlags, ...newData };
  });
};

export const isLaunchDarklyFlagOn = (flagName: string): boolean => (
  updatedFlags[flagName]?.current
  || (launchDarklyClient.variation(flagName, false) as boolean)
);
