/* eslint-disable camelcase */
export const SET_WEBSITES = 'SET_WEBSITES';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const LOGOUT = 'LOGOUT';

export interface ISite {
  assignPriority: string[];
  autoAssign: any[];
  availableSmartTriggers: string[];
  companyId: string;
  creationDate: string;
  deleted: boolean;
  email: string;
  enableChat: boolean;
  id: string;
  labs: object;
  scriptInstalled: boolean;
  tags: any[]
  title: string;
  type: string;
  url: string;
}

export interface IWebsite {
  sites: ISite[];
  hasAnyScriptInstalled: boolean;
}

export interface IUser {
  allowedWebsiteIds: any[]
  creationDate: string;
  deletable: boolean;
  deleted: boolean;
  delighted: boolean;
  email: string;
  hashId: string;
  id: string;
  imageUrl: string;
  intercomHashId: string;
  isSuperUser: boolean;
  language: string;
  mobileNotificationsSilenced: boolean;
  name: string;
  notificationsSilenced: boolean;
  permissions: string[];
  privileges: string;
  roles: string[];
  thumbnailUrl: string;
  tutorials: any[]
}
export interface IAccount {
  affiliate: string;
  affiliateProgramCode: string;
  allowReferralAccess: boolean
  autoBuyLeadPackageEnabled: boolean;
  autoRefreshContactsList: boolean
  businessTeamSize: string;
  chatUrl: string;
  communicationChannels: any[]
  companySize: string;
  contactName: string;
  countryId: string;
  creationDate: string;
  customUOCLimit: number;
  daysToLeadResetDate: number;
  email: string;
  features: {
    available: {
      LIVE_CONVERSATION_HISTORY: boolean;
    };
    limits: object;
  }
  forceChargebee: boolean;
  forceMercadoPago: boolean;
  googleMccId: string;
  hasInstalledScript: boolean;
  hasSubscription: boolean;
  hashId: string;
  hideLeads: boolean;
  id: string;
  isCliengoReseller: boolean;
  isMercadoPago1: boolean;
  isWhatsAppBasic: boolean;
  isWhatsAppFree: boolean;
  isWhitelabelFlex: boolean;
  knowledgeOrigin: string;
  labs: {
    pay_fbchatleads: boolean;
    conversation_json: boolean;
    emptystate_leads: boolean;
    new_pricing_reseller: boolean;
    ecommerce: boolean;
  }
  language: string;
  leadCount: number;
  leadCountTotal: number;
  leadLimit: number;
  leadResetDate: string;
  marketingCampaignsInfo: object;
  name: string;
  phone: string;
  planCurrency: string;
  planName: string;
  preSelectedLeads: number;
  quickstartSteps: {
    CREATE_ACCOUNT_COMPLETED: boolean;
    CHOOSE_APPEARANCE_VISITED: boolean;
    CHOOSE_APPEARANCE_COMPLETED: boolean;
    CUSTOMIZE_CONVERSATION_VISITED: boolean;
    CUSTOMIZE_CONVERSATION_COMPLETED: boolean;
  }
  registerPhone: string;
  registerRole: string;
  scriptInstallTimestamp: number;
  shouldFirePaymentPixel: boolean;
  shouldFireScriptPixel: boolean;
  showNewPlans: boolean;
  taxId: string;
  taxIdName: string;
  timeZone: string;
  vertical: string;
  whiteLabelEmail: string;
  whiteLabelId: string;
  whiteLabelLogoUrl: string;
  whiteLabelName: string;
}

export interface IData {
  account: IAccount;
  user: IUser;
  flags: Record<string, boolean>;
  features: Record<string, boolean>;
}

export interface State extends IData {
  isAuthenticated: boolean;
  user: IUser;
}
