import io from 'socket.io-client';
import Cookies from 'js-cookie';

import {
  ADD_CHAT,
  REMOVE_CHAT,
  UPDATE_CHAT,
} from '../Store/actions/types';
import { addContextInfo } from '../Store/actions/visitorContext.actions';
import { handleLocalTags } from '../utils/handleLocalTags';
import { addMessage } from '../Store/Message/Actions';
import { getPreview, getLocalStorage } from '../utils';
import { LOCAL_TAGS_KEY } from '../Constants';

import { translate } from './translate';
import config from './config';
import { messageIsCommand } from './helpers/messageIsCommand';

export const socketActions = {
  ADD_PARTICIPANT: 'addparticipant',
  NEW_LEAD: 'newLead',
  PILAR_CONFESSED: 'pilarconfessed',
  COMPOSING: 'composing',
  NEW_CHAT: 'newChat',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  SHUT_UP: 'shutup',
  CLOSE_CONVERSATION: 'close-conversation',
  SEND_MESSAGE: 'send_message',
  VIDEO_CALL: 'askForVideoCall',
  PHONE_CALL: 'askForPhoneCall',
  VISITOR_CONNECTED: 'uoc-visitor-connected',
  VISITOR_DISCONNECTED: 'uoc-visitor-disconnected',
  UPDATE_CHAT: 'updateChat',
  CONVERSATION_UPDATED: 'conversation-updated',
};

const SocketeerConnection = (
  chatInfo,
  dispatch,
  updateChats,
  { user, account },
) => {
  // estos comandos son los que llevan al incio !
  const sentCommands = [socketActions.COMPOSING, socketActions.SHUT_UP];

  const isComand = (msg) => sentCommands.includes(msg);

  const updateConversations = (convers) => {
    if (!convers?.body?.uoc2) {
      updateChats(
        convers?.conversations || convers?.body?.conversations || [],
        user,
      );
    }
  };

  const updateNewLead = (lead) => {
    console.info('llego un nuevo lead ', lead);
  };

  const visitorConnected = (visitor) => {
    console.info('visitorConnected', visitor);

    const visitorObj = {
      channel: visitor.channel || visitor?.body?.channel,
      clientStatus: 'ONLINE',
      id: visitor.conversationId,
      visitorName: 'Cliente',
      websiteId: visitor.websiteId,
    };
    updateChats(visitorObj, user, ADD_CHAT);
  };

  const visitorDisconnected = (visitor) => updateChats(visitor.conversationId, user, REMOVE_CHAT);

  const sendMessage = async (message = {}) => {
    if (message.body === '!newChat') {
      const visitorObj = {
        channel: message.channel ?? '-',
        clientStatus: 'ONLINE',
        id: message.conversationId,
        visitorName: 'Cliente',
        websiteId: message.websiteId,
      };
      updateChats(visitorObj, user, ADD_CHAT);
    }
    if (message.body === '!conversationTag') {
      const localTags = getLocalStorage(LOCAL_TAGS_KEY) || {};
      let conversationTags = [];
      if (!localTags[message.conversationId]) {
        conversationTags = [message.conversationContext];
      } else {
        const currentTags = localTags[message.conversationId].conversationTags || [];
        conversationTags = [...currentTags, message.conversationContext];
      }
      handleLocalTags.set({
        conversationId: message.conversationId,
        conversationTag: message.conversationContext,
        websiteId: message.websiteId,
        conversationTags,
      });
    }
    if (!messageIsCommand(message)) {
      dispatch(addMessage({
        ...message,
      }));
    } else {
      addContextInfo({
        ...message,
        commandName: message.body.replace('!', ''),
      });
    }
  };

  const conversationUpdated = (conversation) => {
    if (conversation.type && conversation.type === 'robot') {
      updateChats(conversation.body, user, UPDATE_CHAT);
    } else {
      updateChats(conversation, user, UPDATE_CHAT);
    }
  };

  const actionsCallbacks = {
    // 'update-online-conversations': updateConversations,
    'uoc-visitor-connected': visitorConnected,
    'uoc-visitor-disconnected': visitorDisconnected,
    'conversation-updated': conversationUpdated,
    newLead: updateNewLead,
    send_message: sendMessage,
  };

  const socket = io(config.urls.SOCKETEER, { transports: ['websocket'] });

  socket.on('connect', () => {
    const clgoVariables = {
      action: 'user-connect',
      userName: user.name,
      userIdHash: user.hashId,
      companyIdHash: account.hashId,
      jwt: Cookies.get('jwt'),
    };
    socket.emit('user-connect', JSON.stringify(clgoVariables));
  });

  socket.once('update-online-conversations', (conversations) => {
    updateConversations(conversations);
  });

  Object.values(socketActions).forEach((action) => {
    socket.on(action, (msg) => {
      if (Object.keys(actionsCallbacks).includes(action)) { actionsCallbacks[action](msg); }
    });
  });

  const sendMessageOnSocket = (msg) => {
    if (msg.body) {
      if (isComand(msg.body)) {
        msg.body = `!${msg.body}`; // eslint-disable-line no-param-reassign
        msg.message.text = msg.body; // eslint-disable-line no-param-reassign
      } else {
        const name = user?.name || translate('unknown');
        const messageDate = new Date();
        const lastMessageUpdated = getPreview({}, name, msg.body, messageDate);
        if (lastMessageUpdated) {
          updateChats({
            id: msg.conversationId,
            properties: [
              {
                field: 'lastMessage',
                value: lastMessageUpdated,
              },
              {
                field: 'lastMessageDate',
                value: messageDate,
              },
            ],
          }, user, 'UPDATE_ONE_CHAT');
        }
      }
    }
    socket.emit(msg.action, JSON.stringify(msg));
  };

  const sendAction = (action, conversation) => {
    socket.emit(
      action,
      JSON.stringify({
        action,
        type: 'text/plain',
        conversationId: conversation.id,
        websiteId: conversation.websiteId,
        companyId: conversation.companyId,
      }),
    );
  };

  return {
    sendMessage: sendMessageOnSocket,
    sendAction,
  };
};

export default SocketeerConnection;
