import {
  SET_APP_SLIDE,
  OPEN_SLIDE,
  CLOSE_SLIDE,
  CLOSE_LITTEN_SLIDE, OPEN_LITTEN_SLIDE, SET_LITTEN_SLIDE
} from './types';

const setSlide = (decoded, action) => ({
  type: action,
  payload: decoded,
});

export const saveSlide = (slide, action = SET_APP_SLIDE) => async (dispatch) => {
  dispatch(setSlide(slide, action));
};

export const closeSlide = () => async (dispatch) => dispatch({ type: CLOSE_SLIDE });
export const openSlide = () => async (dispatch) => dispatch({ type: OPEN_SLIDE });

export const saveLittenSlide = (slide, action = SET_LITTEN_SLIDE) => async (dispatch) => {
  dispatch(setSlide(slide, action));
};
export const closeLittenSlide = () => async (dispatch) => dispatch({ type: CLOSE_LITTEN_SLIDE });
export const openLittenSlide = () => async (dispatch) => dispatch({ type: OPEN_LITTEN_SLIDE });
