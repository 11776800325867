import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import Cookies from 'js-cookie';

import { AppState } from '../index';
import config from '../../services/config';
import setAuthToken from '../../services/setAuthToken';
import { featureAccessAdapter } from '../../utils/featureAccess';

import {
  IAccount, IData, ISite, IWebsite, IUser,
} from './Types';
import {
  logout, setCurrentUser, setUsers, setWebsites,
} from './Action';

export const fetchAccountData = (flagShouldShowTags: boolean):
ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    const account: { data:IAccount } = await axios.get(
      `${config.urls.API_URL}/account?withFeatures=true`,
    );
    const plan: {data: JsonObject} = await axios.get(`${config.urls.API_URL}/account/plan`);
    const user: {data:IUser} = await axios.get(`${config.urls.API_URL}/users/me`);
    const websites: {data:{results: ISite[], hasAnyScriptInstalledresults: {scriptInstalled: boolean}[]}} = await axios.get(`${config.urls.API_URL}/sites`);
    const allUsers: {data: {results: IUser}} = await axios.get(
      `${config.urls.API_URL}/users?includeDeleted=true`,
      { withCredentials: true },
    );
    const planId = plan.data.id;
    const data: IData = {
      account: account.data,
      user: user.data,
      flags: { flagShouldShowTags },
      features: featureAccessAdapter(planId as string),
    };

    const dataSites: IWebsite = {
      sites: websites.data.results,
      hasAnyScriptInstalled: websites.data.results.some(
        (web) => web.scriptInstalled,
      ),
    };
    dispatch(setCurrentUser(data));
    dispatch(setWebsites(dataSites));
    dispatch(setUsers(allUsers.data.results));
  } catch (error) {
    console.info(error);
  }
};

export const logoutUser = (history: {push: (val: string) => void}):
ThunkAction<void, AppState, null, Action<string>> => (dispatch) => {
  Cookies.remove('jwt');
  setAuthToken(false);
  dispatch(logout());
  history.push('/login');
};
