import Colors from './colors';
import helpfulLink from './helpfulLink';
import { headerHeight } from './Styles.contrains';
import executeOnlyOneTime from './executeOnlyOneTime';
import isEmpty from './is-empty';
import getMessageBody from './getMessageBody';
import { getOldChatItems, setOldChatItems } from './oldChatItems';
import getChatsStructure from './getChatsStructure';
import { getPreview, getLastMessagePreview } from './messagePreview';
import capitalize from './capitalize';
import { getLocalStorage } from './getLocalStorage';
import { setLocalStorage } from './setLocalStorage';
import { deleteLocalStorage } from './deleteLocalStorage';
import { handleLocalTags } from './handleLocalTags';
import isTaken from './isTaken';

const isDeviceMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export {
  capitalize,
  Colors,
  headerHeight,
  isDeviceMobile,
  isMobile,
  helpfulLink,
  executeOnlyOneTime,
  isEmpty,
  getMessageBody,
  getOldChatItems,
  setOldChatItems,
  getChatsStructure,
  getPreview,
  getLastMessagePreview,
  getLocalStorage,
  setLocalStorage,
  deleteLocalStorage,
  isTaken,
  handleLocalTags,
};
