export default {
  contact: 'Contato',
  unknown: 'Estranho',
  client: 'Cliente',
  minute: 'minuto',
  of: 'de',
  return_crm: 'De volta à plataforma',
  notifications_menu: 'Notificações',
  visitors_section: {
    visitors: 'Visitantes',
    without_operators: 'Sem operadores',
    chatting: 'Outros operadores',
    all: 'Todos',
    all_sites: 'Todos os sites',
    select_a_site: 'Escolha um site',
    select_a_tag: 'Escolha uma tag',
    tagIsPremium: 'Você pode filtrar suas conversas a partir do plano Premium.',
    search_chat: 'Procurar chat',
    online: 'Online',
    offline: 'Offline',
    no_chats_found: 'Não foi possível encontrar chats para sua pesquisa.',
    review_filter: 'Verifique o filtro que você escolheu ou tente outra pesquisa.',
    general_table: {
      name: 'Nome',
      channel: 'Canal',
      actions: 'Ações',
      state: 'Estado',
      last_message: 'Última mensagem',
      assigned_to: 'Atribuído a',
      condition: 'Condição',
      intervened_by: 'Contatado por',
      view_chat: 'Ver chat',
      two_hr_ago: 'Há 2 horas',
    },
    contact_details: {
      contact_details: 'Detalhe do contato',
      general_info: 'Informações gerais',
      browser_information: 'Informações de navegação',
      first_contact: 'Primeiro contato',
      site: 'Site',
      source: 'URL',
      medium: 'Médio',
      device_information: 'Informação do dispositivo',
      source_device: 'Dispositivo de origem',
      ip_address: 'Direção IP',
      operating_system: 'Sistema Operativo',
      browser: 'Navegador',
    },
    chat_preview: {
      chat_title: 'Chat',
      chat: 'Converse',
      enter_chat_msg: 'Você está prestes a entrar nesta conversa.',
      inactive_robot_msg: 'O robô ficará inativo para esse cliente, peça os dados que faltam.',
      dont_show_again: 'Não mostre esta caixa de diálogo novamente.',
      cancel: 'Cancelar',
      continue: 'Prosseguir',
      enter_chat: 'Ingressar para o chat',
      is_chatting_with_contact: ' está conversando com esse contato.',
    },
    intervention_error: {
      chat_already_taken_error: 'Conversa não disponível',
      chat_already_taken_info: 'Esta conversa já foi intervencionada por um operador, por favor selecione outra.',
    },
    warning_message: {
      chat_intervened: ' Este chat é acessado a partir do site ',
      chat_disclaimer: ', si se ingresa al chat, no se silenciará a ningún participante.',
    },
    all_tags: 'Todos os rótulos',
  },
  my_chats_section: {
    my_chats: 'Meus chats',
    active_chats: ' chats',
    without_operators: 'Sem operadores',
    end_chat: 'Finalizar',
    close_chat_question: 'A conversa está prestes a terminar',
    close_warning:
      'Se você finalizar a conversa sem obter um e-mail ou telefone, ela não aparecerá na lista de clientes.',
    unread_msg: 'Mensagens não lidas',
    transfer_chat: 'Transferir chat',
    transfer_to: 'Transferir para',
    write_reply: 'Escreva uma resposta',
    choose_agent: 'Escolha um operador online',
    when_transfer: 'Ao transferir, você não poderá mais conversar com o contato.',
    you_transferred_to: 'Você transferiu o chat para',
    undo: 'Desfazer',
    no_operators: 'Sem operadores online',
    to_transfer_you_need: 'Para transferir um chat você precisa outro operador conectado.',
    understood: 'Entendido',
    interacctions_with_conversation: {
      you_joined_chat: 'Você entrou no chat',
      joined_chat: 'entrou no chat',
      leave_chat: 'Eu termino o chat',
      transferred_to_you: 'transferiu o chat para você',
      transferred_from_to: 'transferiu o chat para',
      finished_conversation: 'não está online, você ainda pode ver este chat da História.',
      end_chat: 'O chat terminou em',
      last_message: 'Última mensagem em',
    },
    chat_multimedia: {
      errors: {
        type_not_allowed: 'As extenssões do arquivo selecioonado não são permitidas',
        max_file_length: 'O arquivo selecionado supera o limite de tamanho (máximo 60MB)',
        general_error: 'Ocorreu um erro ao tentar carregar o arquivo, tente mais tarde',
        load_file_error: 'Não podemos carregar o arquivo',
      },
      type_extensions_title: 'Quais arquivos não consigo anexar?',
      type_extensions: 'https://help.cliengo.com/hc/pt/articles/360044633033',
      write_message: 'Escreva seus comentários',
      file_format_error:
        'Alguns arquivos não podem ser carregados devido ao formato. Você pode encontrar mais informações em nossa ',
      help_center: 'central de ajuda.',
      cancel: 'Cancelar',
      add_more: 'Adicionar',
      remove_file: 'Eliminar arquivo',
    },
    emoji_section: {
      smileys_people: 'Sorrisos e pessoas',
      animals_nature: 'Animais e natureza',
      travel_places: 'Locais e viagens',
      activities: 'Atividades',
      objects: 'Objetos',
      symbols: 'Símbolos',
      recently_used: 'Recentes',
    },
    pick_emoji: 'Selecione emoji',
    button_send: 'Enviar',
  },
  history_section: {
    history: 'Histórico',
    chat_history: 'Histórico de conversa',
    lead: 'Lead',
    no_lead: 'Não foi lead',
    intervened: 'Intervindo',
    load_more: 'Carregar mais',
    allSite: 'Todos los sitios',
    operatorFilterTitle: 'A conversa interveio',
    channelFilterTitle: 'Canal',
    conditionFilterTitle: 'doença',
    infOnDurationChats: 'chats dos últimos 7 dias.',
    form: {
      searchVisitor: 'Procura por nome',
      from: 'a partir de',
      to: 'até',
      cleanFilters: 'Filtros limpos',
      applyFilters: 'Aplicar filtros',
    },
  },
  no_chats_available_history: {
    title: 'Ainda não tem chats para revisar',
    legend: 'Aqui vamos mostrar-lhe todas as conversas dos seus visitantes com a Cliengo.',
    checklist: {
      label: 'Você verá os chats dos contatos que:',
      points: ['Conversaram com o chatbot', 'Os operadores intervieram', 'Não se tornaram leads'],
    },
  },
  no_chats_available_history_search: {
    title: 'Não foi possível encontrar chats para sua pesquisa.',
    legend: 'Verifique o filtro que você escolheu ou tente outra pesquisa.',
  },
  empty_state_section: {
    no_chats_available: 'Você não tem chats disponíveis para intervir ',
    see_visitors_chatting: 'Aqui você verá os visitantes que estão conversando com o chatbot.',
    traffic_advise: 'Aconselhamos aumentar o tráfego em seu site.',
    talk_to_visitor: 'Você quer falar com seus visitantes?',
    into_chats_no_operators: 'Entre nos chat',
    no_operator_link: 'sem operador.',
    no_visitors_chatting_now: 'Sem visitantes conversando com os operadores ',
    no_visitors_logged_now: 'Não têm visitantes registrados neste momento',
    no_chats_progress: 'Você não tem chats em andamento',
    want_talk_clients_question: 'Quer falar com seus clientes?',
    see_visitors_chat: 'Aqui verá todos os visitantes que estão conversando com o chatbot e os operadores.',
    want_talk_visitors: 'Você quer falar com seus visitantes?',
    get_chat_without_operator: 'Entre no chat de qualquer um dos visitantes que estão',
    no_chats_to_see: {
      here_show_all_chats:
        'Aqui vamos mostrar-lhe todas as conversas dos seus visitantes com a Cliengo. Você verá os chats dos contatos que:',
      chat_with_bot: 'Conversaram com o chatbot',
      operators_intervened: 'Os operadores intervieram',
      not_become_lead: 'Não se tornaram leads',
    },
    no_chats_search: 'Verifique o filtro que você escolheu ou tente outra pesquisa.',
    connect_chatbot: 'Conecte seu chatbot',
    want_talk_clients: 'a um site e responda às perguntas dos visitantes.',
    advise_increase_traffic: '💡 Aconselhamos aumentar o tráfego em seu site.',
    no_message_error: {
      error_title: 'Oops! Eu acho que houve um erro',
      error_content: 'Não conseguimos encontrar o chat que você estava procurando.',
    },
  },
  visitor_context: {
    header: {
      title: 'Dados do visitante',
    },
    tags: {
      title: 'Tag',
      SHOULD_INTERVENE: 'Intervir',
    },
    general_information: {
      title: 'Informações gerais',
      empty_state_section: '',
      tooltip_content: 'Informações gerais do cliente',
    },
    bot_response_module: {
      title: 'Opções que eles selecionaram ao interagir com o bot',
      empty_state_section: 'Respostas',
      tooltip_content: 'não selecionou nenhuma opção',
    },
    navigation_information: {
      title: 'Informações de navegação',
      empty_state_section: '',
      tooltip_content: 'Informações de navegação',
      keynameSite: 'Site',
      keynameURL: 'URL',
    },
    browsing_history: {
      title: 'Histórico de navegação',
      empty_state_section: '',
      tooltip_content: 'Sites visitados até agora',
    },
    visitor_inquiries: {
      title: 'Consultas de visitantes',
      empty_state_section: 'Ainda não detectamos nenhuma consulta',
      tooltip_content: 'Extraímos da conversa algumas dúvidas que podem ser do seu interesse',
    },
  },
};
