import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import TableLit from '../../Components/General/Table/TableLit.component';
import { saveSlide } from '../../Store/actions/slide';
import analytics, { EVENTS } from '../../services/analytics';
import { hasLabEcom } from '../../labs/hasLabEcom';
import { StateAllChats } from '../../Interfaces/AllChats';
import { hasFeatureAccess } from '../../utils/featureAccess';

import useSortingItems from './sortingTable';
import { TableAllChatsProps } from './AllChatsTypes';

function trackOpenChatPreviewEvent() {
  analytics.trackEvent({
    name: EVENTS.PREVIEW_CHAT,
  });
}

function trackContactDetailsEvent() {
  analytics.trackEvent({
    name: EVENTS.CONTACT_DETAIL,
    label: 'visitors',
  });
}

const TableAllChats = ({
  slidePanel,
  saveSlide,
  itemsTableLit,
  loading,
  noData,
  checkEmpty,
  showAsigned,
}: TableAllChatsProps) => {
  const [items, setItems] = useState(itemsTableLit);
  const history = useHistory();
  const location = useLocation();

  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();

  useEffect(() => {
    setItems([...itemsTableLit]);
  }, [itemsTableLit]);

  const {
    sortByChannel,
    sortByStatus,
    sortByLastContactDate,
    sortByVisitorName,
    sortByOperatorName,
    nameHeader,
    channelHeader,
    statusHeader,
    lastContactHeader,
    chattingWithHeader,
    actionsHeader,
    tags,
  } = useSortingItems(items, setItems);

  const headerTableLit = [
    lastContactHeader,
    ...(hasLabEcom() && hasFeatureAccess('showTagColumns') ? [tags] : []),
    statusHeader,
    nameHeader,
    channelHeader,
    ...(showAsigned ? [chattingWithHeader] : []),
    actionsHeader,
  ];

  const onRowSelectedCallback = async (data: { key: string; id: string }) => {
    if (data?.key !== 'actions') {
      trackContactDetailsEvent();
    } else {
      trackOpenChatPreviewEvent();
    }

    query.set('chatId', data?.id);
    if (data?.key === 'actions') query.set('show', 'true');
    else query.delete('show');
    history.replace({ search: query.toString() });

    await saveSlide({
      open: true,
      content: data,
    });
  };

  const sortChatsBy = (header: { key: string; value: string }): void => {
    /** map of sorting functions */
    const sortingFunctions: { [key: string]: (sort: string) => void } = {
      /** sort by `status`. Wheter is `ONLINE` or `AWAY` */
      status: sortByStatus,
      /** sort by `lastContact` date */
      lastContact: sortByLastContactDate,
      /** sort by name (aka visitor name) */
      name: sortByVisitorName,
      /** sort by `chattingWith` (aka operator name) */
      chattingWith: sortByOperatorName,
      /** sort by `channel` */
      channel: sortByChannel,
    };
    return sortingFunctions[header.key](header.value);
  };

  return (
    <TableLit
      header={headerTableLit}
      items={items}
      sortCallback={(header: { key: string; value: string }) => sortChatsBy(header)}
      loading={loading}
      rowSelectedCallback={onRowSelectedCallback}
      rowSelected={slidePanel?.content?.id as string}
      noData={noData}
      checkEmpty={checkEmpty}
      isOpenSlide={slidePanel!.open}
      isLittenPanelOpen={slidePanel!.isLittenPanelOpen}
    />
  );
};

const mapStateToProps = (state: StateAllChats) => ({
  slidePanel: state.slidePanel,
});

export default connect(mapStateToProps, { saveSlide })(TableAllChats);
