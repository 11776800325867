import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';

import Tooltip from '../Tooltip/Tooltip';
import { translate } from '../../services/translate';
import { hasLabEcom } from '../../labs/hasLabEcom';
import config from '../../services/config';
import { hasFeatureAccess } from '../../utils/featureAccess';

import { TagSelectorSkeleton, TagSelectorSkeletonContianer } from './TagSelectorSkeleton';
import TagsSelector from './TagSelector.style';
import DropdownIndicator from './DropdownIndicator';

const TagSelector = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const history = useHistory();
  const websiteId = query.get('web'); // para ver si abre detalles o no
  const [tags, setTags] = useState([]);
  const [selectorTags, setSelectorTags] = useState([]);
  const [selectedQueryTags] = useState(() => {
    const queryTags = query.get('tags');
    if (queryTags) {
      return decodeURIComponent(queryTags).split(',').map((t) => ({
        label: t,
        value: t,
      }));
    }

    return [];
  });

  const getTags = async () => {
    const response = await axios.get(`${config.urls.API_URL}/projects/allCompanyConversationTags`);
    if (response.status === 200) {
      const { data } = response;
      setTags(data);
    } else {
      console.info('error getting tags', { response });
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    let auxTags = [];

    if (websiteId) {
      auxTags = tags
        .filter((tag) => tag.website_id === websiteId)
        .map((tag) => tag.allConversationTags)
        .flat(Infinity);
    } else {
      auxTags = tags.map((tag) => tag.allConversationTags).flat(Infinity);
    }

    const websiteTags = [...new Set(auxTags)].map((tag) => ({ label: tag, value: tag }));
    setSelectorTags(websiteTags);
  }, [websiteId, tags]);

  const onTagSelected = (selectedTags) => {
    if (selectedTags.length > 0) {
      const encodedTags = encodeURIComponent(selectedTags.map((t) => t.value).join(','));
      query.set('tags', encodedTags);
    } else {
      query.delete('tags');
    }
    history.replace({ search: query.toString() });
  };

  if (!hasLabEcom() || !hasFeatureAccess('showTagFilter')) return null;

  if (!hasFeatureAccess('useTagFilter')) {
    return (
      <TagSelectorSkeletonContianer>
        <TagSelectorSkeleton>
          <span>{translate('visitors_section.select_a_tag')}</span>
          <i className="icon icon-cheveron-down" />
        </TagSelectorSkeleton>
        <div className="tag-tooltip-container">
          <Tooltip tooltipWidth="240px" tooltipHeight="40px" direction="top" content={translate('visitors_section.tagIsPremium')} alignmentX="-50%">
            <i className="icon icon-candado" />
          </Tooltip>
        </div>
      </TagSelectorSkeletonContianer>
    );
  }

  return (
    <TagsSelector
      options={selectorTags}
      isMulti
      closeMenuOnSelect
      placeholder={translate('visitors_section.select_a_tag')}
      noOptionsMessage={() => 'No hay mas tags para seleccionar'}
      onChange={onTagSelected}
      defaultValue={[...selectedQueryTags]}
      className="tags-select-container"
      classNamePrefix="tags-select"
      components={{ DropdownIndicator }}
    />
  );
};

export default TagSelector;
