/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination, EllipsisLoader } from 'cliengo-ui';

import {
  MasterDetailLeftAside,
  MasterDetailMain,
  MasterDetailOutsideContainer,
  NewSlideContainer,
} from '../../GlobalStyles/Layouts';
import { ContactDetailHistoryContainer } from '../../Components/Panels/ContactDetails';
import { translate } from '../../services/translate';
import { Images } from '../../Constants';
import ConversationDetails from '../../Components/Panels/ConversationDetails';
import FilterHistory from '../../Components/FilterHistory/FilterHistory';
import { closeSlide } from '../../Store/actions/slide';
import {
  Convs,
  Socket,
  StateAllChats,
  User,
} from '../../Interfaces/AllChats';
import { Chat } from '../../Interfaces/History';
import analytics, { EVENTS } from '../../services/analytics';

import TableHistory from './TableHistory';

interface Props {
  selectedChat: string;
  hideChats: boolean;
  convs: Convs[];
  setPage: (page?: {page: number}, query?: string) => void;
  convDataRaw: Chat[];
  paginationConfig: {
    page: number;
    page_count: number;
    page_size: number;
    total_count: number;
  } | undefined;
  socket: Socket;
}
declare global {
  interface analytics {
    trackPage: (val: string) => void;
  }

}
const HistoryComponent = ({
  selectedChat,
  hideChats,
  convs,
  setPage,
  convDataRaw,
  paginationConfig,
  socket,
}: Props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [typeEmpty, setTypeEmpty] = useState('emptyTable');
  const [isFiltered, setIsFiltered] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(true);
  const [query, setQuery] = useState<string>('');
  const dispatch = useDispatch();
  const websites = useSelector((state: StateAllChats) => state.websites);
  const webs = websites.sites;
  const slidePanel = useSelector((state: StateAllChats) => state.slidePanel);
  const users = useSelector((state: StateAllChats) => state.users);
  const me = useSelector((state: StateAllChats) => state.auth.user);

  const isOpenSlide = useSelector(
    (state: StateAllChats) => state.slidePanel.open,
  );

  const usersFilter = users.filter((user: User) => {
    let aprov = me.permissions.includes('admin') || user.allowedWebsiteIds?.length === 0;
    if (!aprov) {
      me.allowedWebsiteIds?.forEach((id) => {
        aprov = aprov || user.allowedWebsiteIds?.includes(id);
      });
    }
    return aprov;
  });

  let sitesStore = webs.map((site) => ({
    label: site.title,
    value: site.id,
  }));
  sitesStore = [
    { label: translate('history_section.allSite'), value: 'all' },
    ...sitesStore,
  ];

  const newPage = (page?: {page: number}, query?: string) => {
    if (slidePanel.open) dispatch(closeSlide());
    setLoadingData(true);
    setPage(page, query);
    setTimeout(() => {
      setLoadingData(false);
    }, 1000);
  };

  const getCountRows = () : number | '' => {
    if (!paginationConfig) return '';
    let countData: number = paginationConfig.page_count;
    if (paginationConfig.page > 1) {
      countData += paginationConfig.page_size * (paginationConfig.page - 1);
    }
    return countData;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && slidePanel.open) {
      dispatch(closeSlide());
    }
    return () => {
      isMounted = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (convs.length <= 0 && isFiltered) setTypeEmpty('filterEmpty');
  }, [convs, isFiltered]);
  const isEmpty = () => ({
    img:
      typeEmpty === 'emptyTable'
        ? Images.emptyDataTable
        : Images.emptyDataTableFilter,
    title:
      typeEmpty === 'emptyTable'
        ? translate('no_chats_available_history.title')
        : translate('no_chats_available_history_search.title'),
    subtit:
      typeEmpty === 'emptyTable'
        ? translate('no_chats_available_history.legend')
        : translate('no_chats_available_history_search.legend'),
    checklist:
      typeEmpty === 'emptyTable'
        ? {
          label: translate('no_chats_available_history.checklist.label'),
          points: translate('no_chats_available_history.checklist.points'),
        }
        : null,
  });

  useEffect(() => { analytics.trackPage(EVENTS.HISTORY); }, []);

  return (
    <MasterDetailOutsideContainer
      isOpenSlide={isOpenSlide}
      fromHistory={hideChats}
      isHistory
    >
      {/* La lista de contactos */}
      <MasterDetailLeftAside
        className={selectedChat ? 'chatView' : ''}
        isHistory
        fromHistory={isOpenFilter}
      >
        <FilterHistory
          data={{ sitesStore, usersFilter }}
          setIsOpenFilter={setIsOpenFilter}
          setIsFiltered={setIsFiltered}
          setQuery={setQuery}
          getDataFilter={(query: string) => newPage(undefined, query)}
        />
      </MasterDetailLeftAside>

      <MasterDetailMain
        isOpenSlide={isOpenSlide}
        style={{ textAlign: 'justify' }}
        className={`history ${selectedChat ? 'chatView' : ''}`}
      >
        {loadingData ? (
          <div
            style={{
              height: '80vh',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <EllipsisLoader color="#7D60F5" />
          </div>
        ) : (
          <div className="is-flex is-flex-direction-column is-full-height">
            <div className="is-flex is-full-width is-justify-content-end p-1 box-sizing-border-box filter-mobile">
              <i
                className="icon-filter mobile-icon"
                onClick={() => setIsOpenFilter(true)}
                aria-hidden="true"
              />
            </div>
            <TableHistory
              itemsTable={convs}
              loading={false}
              noData={isEmpty()}
              checkEmpty={false}
            />
            {paginationConfig && (
              <div className="row pl-1">
                <Pagination
                  currentPage={paginationConfig.page}
                  goToPage={(page: {page: number}) => (
                    isFiltered ? newPage(page, query) : newPage(page))}
                  limit={paginationConfig.page_size}
                  maxPages={2}
                  total={paginationConfig.total_count}
                />
                <div className="pl-1 fontSize-small">
                  <div className="color-purple row pl-1">
                    {`${getCountRows()} ${translate('of')} ${
                      paginationConfig.total_count
                    } chats`}
                  </div>
                  <div className="row pl-1 mt-1">
                    <i className="icon-info" style={{ marginRight: 5 }} />
                    {' '}
                    {`${translate('history_section.infOnDurationChats')}`}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </MasterDetailMain>

      {/* El slide para ver la info */}
      <NewSlideContainer isOpenSlide={isOpenSlide}>
        {slidePanel?.content?.key === 'actions' ? (
          <ConversationDetails socket={socket} hideFooter websites={websites} convs={convs} />
        ) : (
          convs && <ContactDetailHistoryContainer convDataRaw={convDataRaw} />
        )}
      </NewSlideContainer>
    </MasterDetailOutsideContainer>
  );
};

export default HistoryComponent;
